<template>
  <div>
    <column-picker
      :attach="false"
      :clearable="true"
      :label="$t('t.Date')"
      :filters="{ 'document-types': cameleonDocTypes}"
      :readonly="readonly"
      :selected-col-id.sync="computedColumnDate"
      :dense="dense"
      :rules="rules"
      date-cols-only
      no-cols-relation
      currency-disabled
      class="mb-5"
    />
    <filter-params
      v-model="computedFilter"
      :auto-filter-type="computedViewType"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  inheritAttrs: false,
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    FilterParams: () => import('@/pages/cameleon/columns/paramsType/filter-params/filter-params')
  },
  computed: {
    cameleonDocTypes () {
      const cameleonDocTypes = new IncExc()
      cameleonDocTypes.include([this.computedViewType])
      return cameleonDocTypes
    },
    computedViewType () {
      return this.viewType
    },
    computedFilter: {
      get () {
        return this.dataFilter
      },
      set (v) {
        this.dataFilter = v
        this.dataDocumentEmit()
      }
    },
    computedColumnDate: {
      get () {
        return this.dataColumnDate
      },
      set (v) {
        this.dataColumnDate = v
        this.dataDocumentEmit()
      }
    },
    isNotRequired () {
      return !this.required
    },
    rules () {
      return [this.isNotRequired || this.$t('t.IsRequired')]
    }
  },
  data () {
    return {
      dataFilter: null,
      dataColumnDate: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = !this.dataColumnDate ? null : { columnDate: this.dataColumnDate, filter: this.dataFilter }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    required: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataColumnDate = v?.columnDate
        this.dataFilter = v?.filter ?? {}
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
